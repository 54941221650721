"use client";

import dynamic from "next/dynamic";
import { ReactNode } from "react";
import { Toaster } from "react-hot-toast";
import { ROUTES } from "src/common/config/routes";
import { getShowLiveChat } from "src/common/utils/get-show-live-chat";
import { Navbar } from "src/employee/onboarding/components/Navbar";

const ChatOnboarding = dynamic(
  () =>
    import("src/employee/onboarding/components/ChatOnboarding").then(
      (mod) => mod.ChatOnboarding,
    ),
  {
    ssr: false,
  },
);

export interface LoginLayoutProps {
  children: ReactNode;
}

export default function LoginLayout({ children }: LoginLayoutProps) {
  const showLiveChat = getShowLiveChat();

  return (
    <>
      <Toaster />

      {showLiveChat && <ChatOnboarding />}

      <Navbar logoHref={ROUTES.HOME} showLiveChat={showLiveChat} />

      <div className="relative top-16 pb-16">{children}</div>
    </>
  );
}
