import { ReactNode, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";

export interface OnboardingNavbarContainerProps {
  children: ReactNode;
}

export function OnboardingNavbarContainer({
  children,
}: OnboardingNavbarContainerProps) {
  const [created, setCreated] = useState(false);
  useEffect(() => {
    setCreated(true);
  }, [created]);
  return (
    <CSSTransition
      classNames="navbar-container-animation"
      in={created}
      timeout={500}
    >
      <nav className="grid h-[60px] auto-cols-fr grid-flow-col items-center justify-between px-5 bg-white md:px-8 fixed w-full z-[99]">
        {children}
      </nav>
    </CSSTransition>
  );
}
