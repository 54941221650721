import { IconProps } from "../FontAwesome/types";

export function Counter(props: IconProps) {
  return (
    <svg
      height="32"
      width="32"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20.8119 3H11.9808V11.4483V19.8965H3.05869C3.05869 19.8965 1.71073 11.4483 11.9353 11.4483C22.1599 11.4483 20.8119 3 20.8119 3Z" />
      <path d="M29.7472 11.4475H20.9161V28.344H11.994C11.994 28.344 10.6461 19.8957 20.8706 19.8957C31.0952 19.8957 29.7472 11.4475 29.7472 11.4475Z" />
    </svg>
  );
}
