import Link from "next/link";
import { ChatButton } from "src/common/components/ChatButton";
import { Counter } from "src/logos/Counter";
import { Counter as CounterLogo } from "src/common/icons/logomark/Counter";
import { OnboardingNavbarContainer } from "../OnboardingNavbarContainer";

export interface NavbarProps {
  logoHref: string;
  showLiveChat: boolean;
}

export function Navbar({ logoHref, showLiveChat }: NavbarProps) {
  return (
    <OnboardingNavbarContainer>
      <div className="max-w-screen-1920 justify-self-center flex w-full items-center">
        <Link
          aria-label="Homepage"
          className="inline-grid h-8 md:h-6 w-auto place-items-center focus-visible:outline-1.5 focus-visible:outline-offset-4 focus-visible:outline-black active:bg-slate-50 h-10"
          href={logoHref}
        >
          <Counter className="h-[24px] w-auto text-accent hidden md:block" />
          <CounterLogo className="block md:hidden stroke-counterGreen-600 stroke-2 w-9 h-9" />
        </Link>

        {showLiveChat && (
          <>
            <div className="flex-col items-end ml-auto">
              <ChatButton />
            </div>
          </>
        )}
      </div>
    </OnboardingNavbarContainer>
  );
}
