import { IconProps } from "../types";

export function CommentLines(props: IconProps) {
  return (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M264 272h-112C138.8 272 128 282.8 128 296S138.8 320 152 320h112C277.3 320 288 309.3 288 296S277.3 272 264 272zM360 176h-208C138.8 176 128 186.8 128 200S138.8 224 152 224h208C373.3 224 384 213.3 384 200S373.3 176 360 176zM256 31.1c-141.4 0-255.1 93.13-255.1 208c0 47.62 19.91 91.25 52.91 126.3c-14.87 39.5-45.87 72.88-46.37 73.25c-6.623 7-8.373 17.25-4.623 26C5.816 474.3 14.38 480 24 480c61.49 0 109.1-25.75 139.1-46.25c28.87 9 60.16 14.25 92.9 14.25c141.4 0 255.1-93.13 255.1-207.1S397.4 31.1 256 31.1zM256 400c-26.75 0-53.12-4.125-78.36-12.12l-22.75-7.125L135.4 394.5c-14.25 10.12-33.87 21.38-57.49 29c7.373-12.12 14.37-25.75 19.87-40.25l10.62-28l-20.62-21.88C69.81 314.1 48.06 282.3 48.06 240c0-88.25 93.24-160 207.1-160c114.7 0 207.1 71.75 207.1 160S370.8 400 256 400z"
        fill="currentColor"
      />
    </svg>
  );
}
