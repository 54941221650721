export function getInWorkHours() {
  let date = new Date();
  const hour = parseInt(
    date.toLocaleString("en-US", {
      timeZone: "America/New_York",
      hour: "2-digit",
      hour12: false,
    }),
  );
  return hour >= 8 && hour < 20 && date.getDay() != 6 && date.getDay() != 0;
}
