import { useContext } from "react";
import { ChatContext, ChatState } from "src/common/contexts/ChatContext";
import { CommentLines } from "src/common/icons/FontAwesome/Regular/CommentLines";
import { XMarkLarge } from "src/common/icons/FontAwesome/Regular/XMarkLarge";
import { HorizontalSpinner } from "src/common/ui/HorizontalSpinner";
import { getInWorkHours } from "src/common/utils/get-in-work-hours";

export function ChatButton() {
  const { chatState, isLoading, isOpen, openChat, setChatError, setIsOpen } =
    useContext(ChatContext);

  async function handleClick() {
    if (!isOpen) {
      setIsOpen(true);

      try {
        switch (chatState) {
          case ChatState.CHAT_IDLE:
            await openChat();
            return;
          default:
            return;
        }
      } catch (error) {
        setChatError(error);
      }
    } else {
      setIsOpen(false);
    }
  }
  const buttonText = getInWorkHours() ? "LIVE CHAT" : "NEED HELP?";
  return (
    <button
      className={`${
        isOpen
          ? "bg-button-tertiary-default text-accent box-border border-2 hover:border-input-focus active:border-input-focus"
          : "bg-button-primary-default px-4 py-2 text-white hover:bg-button-primary-hover active:bg-button-primary-active"
      } flex flex-row place-items-center rounded-full p-3 outline-none ring-offset-3 focus-visible:ring-1.5 focus-visible:ring-black active:scale-[0.97]`}
      disabled={isLoading}
      onClick={handleClick}
      title={isOpen ? "End Chat" : "Chat with Live Support"}
    >
      {isLoading ? (
        <div className="inline-grid h-4 w-4 place-items-center">
          <HorizontalSpinner />
        </div>
      ) : isOpen ? (
        <>
          <XMarkLarge className="h-4 w-4" />
        </>
      ) : (
        <>
          <span className="me-3 font-bold">{buttonText}</span>
          <CommentLines className="h-4 w-4" />
        </>
      )}
    </button>
  );
}
